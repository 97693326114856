@import url(https://www.dieproduktmacher.com/static/styles/main.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  max-width: 60rem;
  margin: 0 auto;
}

.react-tabs__tab {
  font-weight: 600;
  font-size: 1.3125rem;
  padding: 6px 24px !important;
}

.react-tabs__tab:hover {
  color: #ee3a51;
}

.react-tabs__tab--selected {
  color: white !important;
  border: none !important;
  background-color: #ee3a51 !important;
}

.react-tabs__tab-list {
  margin-top: 2em !important;
  margin-bottom: 1.5em !important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Key download */

.key-download-cell {
  display: flex;
  padding: 0.5em;
  position: relative;
  border-color: rgb(170, 170, 170);
  border-width: 1px;
  border-style: solid;
  text-align: left;
  background-color: #f8f8f8;
  font-size: inherit;
  outline: none;
}

.key-download-cell:hover {
  border-color: #ee3a51;
}

.key-download-cell img {
  border-radius: 999px;
  margin-right: 1em;
  width: 5em;
  height: 5em;
}

.key-download-cell .name-label {
  font-weight: 600;
}

.key-download-cell .email-label {
  margin-bottom: 0.4em;
  color: #ee3a51;
}

.key-download-cell svg {
  position: absolute;
  bottom: 1em;
  right: 1em;
}
.key-download-cell svg path {
  fill: rgb(49, 50, 54);
}
.key-download-cell:hover svg path {
  fill: #ee3a51;
}

/* Text encrypt */

.keyselection input[type="checkbox"] {
  -webkit-filter: hue-rotate(132deg);
          filter: hue-rotate(132deg);
}

.keyselection img {
  border-radius: 5px;
  margin: 0 0.5em;
  width: 2.5em;
  height: 2.5em;
}

.keyselection .email-label {
  color: #ee3a51;
}

.keyselection li {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

/* file encrypt */

.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone .selected-file {
  color: rgb(49, 50, 54);
}

.dropzone .dropzone:focus {
  border-color: #ee3a51;
}

button:disabled,
button[disabled] {
  background-color: #666666;
}

